<template>
	<div>
		<div class="sub-tit"><p>수요기업 평가</p></div>
		<div class="search-box evl">
			<SelectComp v-model="srchFilter.evalYn" list="Y:평가,N:미평가" :isAll="true" title="평가유무"/><nbsp/>
			<InputComp v-model="srchFilter.srchTxt" placeholder="프로젝트명" @keyup.enter="getAdmDcoEvalList('1');"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAdmDcoEvalList('1');"/>
		</div>
		<div class="view int_prj none" v-if="!scoEvalList || scoEvalList.length <= 0">
			수요기업 평가가 없습니다.
		</div>
		<div class="list-box" v-else v-for="(evalRow, evalIdx) in scoEvalList" :key="evalIdx">
			<div class="prj_outline">
				<div class="left">
					<div class="regi mr-5">
						<div class="outline" style="display: inline-flex;">
							<div class="label ivl10">프로젝트명</div>
							<div class="value prj">{{evalRow.projectNm}}</div>
						</div>
						<div class="outline">
							<div class="label ivl03" style="margin-right: -3px;">개요</div><div class="value text">{{evalRow.projectDtlDesc}}</div>
						</div>
						<div class="outline">
							<div class="label ivl02">고 객 사</div>
							<div style="display: inline-flex; width: 620px;">
								<div class="prj_outline_clientname">
									{{evalRow.clientNm}}
								</div>
								<div class="prj_outline_clientloc">
									<img src="/images/location.png" alt="위치" />{{evalRow.projectLocCont}}
								</div>
							</div>
							<!-- <div class="value">{{evalRow.clientNm}}</div>							
							<div class="value location"><img src="/images/location.png" alt="위치"/> {{evalRow.projectLocCont}}</div> -->
						</div>
						<div class="outline">
							<div class="label ivl05">산업분야</div>
							<div class="value" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="evalRow.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="evalRow.indtypeClassCd"/></div>
						</div>
					</div>
					<div class="int_box">
						<div class="outline">
							<div class="label ivl02">수 행 사</div><div class="value">{{evalRow.corpNm}}</div>
						</div>
						<div class="outline">
							<div class="label ivl02">담 당 자</div><div class="value">{{evalRow.mberNm}}</div>
						</div>
						<div class="outline">
							<div class="label">개 발 기 간</div><div class="value">{{evalRow.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{evalRow.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
						</div>
					</div>					
				</div>
				<div class="right">
					<p>수요기업 평가</p>
					<template v-if="evalRow.evalGradeGroup">
						<div class="star-evl" v-for="(grdItem, grdIdx) in evalRow.evalGradList" :key="grdIdx">
							<GradeImg type="CLIENT_EVAL_CST" :grade="grdItem.substring(grdItem.indexOf(':') + 1)" />
							<p><SelectComp type="text" cdId="PRO110" :value="grdItem.substring(0, grdItem.indexOf(':'))" /></p>
						</div>
					</template>
					<template v-else>
						<p class="p1">※ 평가를 기다리는 중 입니다!</p>
						<template  v-if="$store.state.userInfo.mberDivCd == '00' || $store.state.userInfo.mberSeq == evalRow.admMgrMberSeq">
							<div class="btn" @click="evalAppr(evalRow.interviewReqSeq)">평가요청 &#9654;</div>
						</template>
						<template v-else-if="evalRow.admMgrMberNm">매니저: {{evalRow.admMgrMberNm}}</template>
						<template v-else>-</template>
					</template>
				</div>
			</div>
			
			<!-- 프로젝트 평가 -->
			<div class="review">
				<template v-if="evalRow.overallOpinCont">
					<div class="layout">
						<div class="photo">
							<img src="/images/cstom_photo.png" alt="사진"/>
						</div>
						<div class="cstom_name">수요기업<span>{{evalRow.corpNm}}</span></div>
					</div>
					<div class="layout">
						<div class="list cstom eval">
							<p>프로젝트 평가<span class="date">{{evalRow.regYmd | date('yyyy.MM.dd')}}</span></p>
							{{evalRow.overallOpinCont}}
						</div>
					</div>
				</template>

				<p class="guide" v-else>※ 평가를 기다리는 중 입니다!</p>
			</div>
		</div>

		<!-- 페이징부분 -->
		<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>

	</div>
</template>

<script>
import GradeImg from '@/components/highpro/GradeImg.vue';
import PagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			// 검색조건
			srchFilter : {
				srchTxt : '',
				evalYn : '',
				tecMberSeq : this.$route.query.tecMberSeq || '',
				pgeIndex : '1',
			},

			scoEvalList : [],

			evalGradList : [],

			prfData : {},

			pageInfo : {}
		}
	},

	components : {
		GradeImg, PagingComp
	},

	mounted() {
		this.getAdmDcoEvalList();
	},

	methods : {
		getAdmDcoEvalList(div) {
			var param = this.srchFilter;
			param.pageUnit = '5';
			param.pageSize = '10';
			if(div) param.pgeIndex = '1';

			this.$.httpPost('/api/mem/adm/evl/getAdmDcoEvalList', this.srchFilter)
				.then(res => {
					// console.log('getAdmDcoEvalList RESULT', res);

					this.scoEvalList = res.data.scoEvalList;
					this.pageInfo = res.data.pageInfo;

					for(var i in this.scoEvalList) {
						if(this.scoEvalList[i].evalGradeGroup) this.scoEvalList[i].evalGradList = this.scoEvalList[i].evalGradeGroup.split(',');
					}
					this.$emit('searchEnd', res.data);
				}).catch(this.$.httpErrorCommon);
		},

		evalAppr(seq) {
			this.$.popup('/adm/mem/MEM912P03', {interviewReqSeq : seq});
		},

		// 페이징 이동
		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.getAdmDcoEvalList();
		},
	}
}
</script>